/* [REGISTER-LOGIN-POPUPS] */

// Login / Forgot password
$(".js-openLoginDialog").on("click", function (e) {
  e.preventDefault();
  Fancybox.close();
  new Fancybox(
    [
      {
        src: "#login-dialog",
        groupAttr: false,
      },
    ],
    {
      on: {
        load: (fancybox, slide) => {},
        ready: (fancybox, slide) => {},
        reveal: (fancybox, slide) => {
          gsap.set(".login-form.lf1", { x: "0", y: "0" });
          gsap.set(".login-form.lf2", {
            x: "300%",
            top: "30px",
            position: "absolute",
            width: "90%",
          });
          $(".login-prev").on("click", function (e) {
            e.preventDefault();
            gsap.to(".login-form.lf1", { x: "-150%" });
            gsap.to(".login-form.lf2", { x: "0" });
          });
          $(".login-next").on("click", function (e) {
            e.preventDefault();
            gsap.to(".login-form.lf1", { x: "0" });
            gsap.to(".login-form.lf2", { x: "300%" });
          });
        },
        done: (fancybox, slide) => {},
        destroy: (fancybox, slide) => {},
      },
    }
  );
});

$(".js-openRegisterDialog").on("click", function (e) {
  e.preventDefault();
  Fancybox.close();
  new Fancybox([
    {
      src: "#register-dialog",
      groupAttr: false,
    },
  ]);
});
