// COMPONENT
$(function () {
  // console.log("accordions.js loaded");

  // $(".js-toggleAllAccordions").on("click", function (e) {
  //   e.preventDefault();
  //   if ($(this).text() === "open all") {
  //     $(".accordion > li").addClass("open").find(".accordion__submenu").slideDown();
  //     $(this).text("close all");
  //   } else {
  //     $(".accordion > li")
  //       .removeClass("open")
  //       .find(".accordion__submenu")
  //       .slideUp();
  //     $(this).text("open all");
  //   }
  // });

  // $(".accordions").on("click", ".js-toggleAccordion", function (e) {
  //   e.preventDefault();
  // });

  // var Accordion = function (el, multiple) {
  //   this.el = el || {};
  //   this.multiple = multiple || false;
  //   var links = this.el.find(".accordion__link");
  //   links.on("click", { el: this.el, multiple: this.multiple }, this.dropdown);
  // };

  // Accordion.prototype.dropdown = function (e) {
  //   var $el = e.data.el,
  //     $this = $(this),
  //     $next = $this.next();

  //   $next.slideToggle();
  //   $this.parent().toggleClass("open");

  //   if (!e.data.multiple) {
  //     $el
  //       .find(".accordion__submenu")
  //       .not($next)
  //       .slideUp()
  //       .parent()
  //       .removeClass("open");
  //   }
  // };
  // var accordion = new Accordion($("#accordion"), true);
});
