/* [CHECKOUT-SELECTION-POPUP START] */
$(".js-openCheckoutDialog").on("click", function (e) {
  e.preventDefault();
  Fancybox.close();
  new Fancybox(
    [
      {
        src: "#checkout-selection",
        groupAttr: false,
      },
    ],
    {
      on: {
        load: (fancybox, slide) => {},
        ready: (fancybox, slide) => {},
        reveal: (fancybox, slide) => {
          gsap.set(".login-form.lf1", { x: "0", y: "0" });
          gsap.set(".login-form.lf2", {
            x: "300%",
            //   top: "30px",
            left: 0,
            position: "absolute",
            width: "100%",
          });
          $(".login-prev").on("click", function (e) {
            e.preventDefault();
            gsap.to(".login-form.lf1", { x: "-150%", position: "absolute" });
            gsap.to(".login-form.lf2", { x: "0", position: "relative" });
          });
          $(".login-next").on("click", function (e) {
            e.preventDefault();
            gsap.to(".login-form.lf1", { x: "0", position: "relative" });
            gsap.to(".login-form.lf2", { x: "-300%", position: "absolute" });
          });
        },
        done: (fancybox, slide) => {},
            destroy: (fancybox, slide) => {
            $(".login-form").removeAttr("style");
        },
      },
    }
  );
});
/* [CHECKOUT-SELECTION-POPUP END] */