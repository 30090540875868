// MAIN JS
  jconfirm.defaults = {
    theme: "modern",
    useBootstrap: false,
  };
$(function () {
  console.log("main.js loaded");

  $("#mobile-nav").hcOffcanvasNav({
    // disableAt: 1024,
    customToggle: $(".m-menu-toggle__button"),
    navTitle: "",
    levelTitles: true,
    pushContent: "main",
    levelTitleAsBack: true,
    position: "left",
  });

  $(".js-formSubmit").on("click", function (e) {
    e.preventDefault();
    submitForm(this);
  });
});

function submitForm(trigger, onSuccess, onError) {
  let $form = $(trigger).closest("form");

  // grecaptcha
  //   .execute("6LdGDiAcAAAAAK8b5xmzsM_CWfpAST-x5MCjlEiI", {
  //     action: $form.attr("data-action"),
  //   })
  //   .then(function (token) {
  //     var $tokenObj = $form.find(".g-token");
  //     if ($tokenObj.length > 0) {
  //       $tokenObj.val(token);
  //     } else {
  //       $form.append(
  //         '<input type="hidden" class="g-token" name="g-recaptcha-response" value="' +
  //           token +
  //           '">'
  //       );
  //     }
  
  $.ajax({
    method: $form.attr("method"),
    url: $form.attr("action"),
    data: new FormData($form[0]),
    processData: false,
    contentType: false,
    dataType: "json",

    beforeSend: function () {
      $form.find("input, select, textarea").removeClass("error");

      $(trigger).addClass("btn--loading");
    },

    complete: function () {
      $(trigger).removeClass("btn--loading");
    },
  })
    .done((res) => {
      if (!res.err) {
        $form.trigger("reset");
        tata.success("Success", `${res.msg}`);
        if (onSuccess) {
          onSuccess(res);
        }
        return;
      }

      if (res.err) {
        tata.error("Error", `${res.msg}`);
        if (res.fields.length) {
          res.fields.map((field) => {
            $form.find(`[name='${field}']`).addClass("error");
          });
        }
        if (onError) {
          onError(res);
        }
      }
    })
    .fail(() => {
      if (onError) {
        onError();
      }
      tata.error("Error", `Something went wrong. Please try again.`);
    });
  // });
}
