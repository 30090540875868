/* [INDEX GALLERY] */

let gallerySwiper;

$(function () {
  if ($('.index-gallery').length) {
    gallerySwiper = new Swiper(".index-gallery__swiper", {
      loop: true,
      speed: 1000,
      slidesPerView: "auto",
      spaceBetween: 15,
    });
  }

  Fancybox.bind("[data-fancybox='index-gallery']", {
    mainClass: "gallery-fancybox",
    infinite: false,
    closeButton: "top",
    showClass: false,
    hideClass: false,
    Thumbs: false,
    Toolbar: false,
    Image: {
      zoom: false,
      fit: "cover",
    },
    on: {
      initCarousel: (fancybox) => {
        // Create main container for caption panel and Fancybox carousel
        const $mainPanel = document.createElement("div");
        $($mainPanel).addClass("fancybox__main-panel");
        $($mainPanel).append(fancybox.$carousel);

        $(fancybox.$backdrop).after($mainPanel);
      },
      load: (fancybox) => {
        $(".fancybox__main-panel").append(fancybox.$closeButton);
      },
    },
    Carousel: {
      on: {
        change: (that) => {
          if (gallerySwiper) {
            gallerySwiper.slideTo(that.page);
            gallerySwiper.update();
          }
          // mainCarousel.slideTo(mainCarousel.findPageForSlide(that.page), {
          //   friction: 0,
          // });
        },
      },
    },
  });
});
