/* [FLOATING INQUIRE] */

$(function () {
  $(".js-openInqForm").on("click", function (event) {
    $(this).next(".inq-form").addClass("inq-form--active");
  });

  $(document).on("click", function (event) {
    if (!$(event.target).closest(".inq-form").length && !$(event.target).closest(".js-openInqForm").length) {
      $(".inq-form").removeClass("inq-form--active");
    }
  });
});
