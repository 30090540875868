/* [HOME SLIDER] */

$(function () {
  var swiper = new Swiper(".home-slider", {
    effect: "fade",
    navigation: {
      nextEl: ".home-slider .swiper-button-next",
      prevEl: ".home-slider .swiper-button-prev",
    },
  });

  // $(window).on("scroll", function () {
  //   if ($(window).scrollTop() > 0) {
  //     $(".header").not("header--fixed").addClass("header--scrolled");
  //   } else {
  //     $(".header").removeClass("header--scrolled");
  //   }
  // });
});
