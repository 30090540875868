/* INQUIRE POPUP */
$(function () {
  $(".js-inquirePopup").on("click", function (e) {
    e.preventDefault();
    let $this = $(this);

    console.log(hasAttr($this, "data-part-number"), $this.data("partNumber"));

    new Fancybox(
      [
        {
          src: $("#inquire-popup").html(),
          type: "html",
        },
      ],
      {
        autoFocus: false,
        mainClass: "inquire-popup-fancy-main",
        on: {
          reveal: function (fancybox, content) {
            hasAttr($this, "data-part-number") &&
              $(content.$content)
                .find("#part-number.inquire-popup__input")
                .val($this.data("partNumber"));
          },
        },
      }
    );
  });

  $(document).on("click", ".js-submitForm", function (e) {
    e.preventDefault();
    submitForm($(this).closest("form"));
  });
});

// check if attribute exists in element
function hasAttr(element, attribute) {
  return element.attr(attribute) !== undefined;
}

// function submit form via AJAX
function submitForm(form) {
  let $form = $(form);
  let $submitButton = $form.find("button[type='submit']");
  let $submitButtonText = $submitButton.html();
  let $submitButtonDisabled = $submitButton.prop("disabled");

  $.ajax({
    type: "POST",
    url: $form.attr("action"),
    data: $form.serialize(),
    beforeSend: function () {
      $submitButton.html(
        "<span class='loading'><img src='/img/icons/loading.gif' alt='loading.gif'> </span>"
      );
      $submitButton.prop("disabled", true);
    },
    success: function (response) {
       tata.success("Success", "Your inquiry has been sent successfully.");
      $submitButton.html($submitButtonText);
      $submitButton.prop("disabled", $submitButtonDisabled);
    },
    error: function (response) {
      tata.error("Error", "Something went wrong. Please try again later.");
      $submitButton.html($submitButtonText);
      $submitButton.prop("disabled", $submitButtonDisabled);
    },
  });
}
