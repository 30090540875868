/* [PRODUCTS GALLERY] */

if ($(".projects-grid").length) {
  let msnry;
  var grid = document.querySelector(".projects-grid");
  imagesLoaded(grid, function () {
    msnry = new Masonry(grid, {
      //options
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      gutter: ".gutter-sizer",
      percentPosition: true,
    });
  });

  $(".js-filterMasontryImages").on("click", function (event) {
    event.preventDefault();
    let relation = $(this).attr("data-rel");
    let text = $(this).text();

    $(".masonry__ttl").text(text);
    $(".projects-filter__link").removeClass("projects-filter__link--active");
    $(this).addClass("projects-filter__link--active");

    if (relation === "0") {
      $(".projects-grid .grid-item").show();
      msnry.layout();
      return;
    }

    $(".projects-grid .grid-item").hide();
    $(".projects-grid  .grid-item[data-rel='" + relation + "']").show(),
      msnry.layout();
  });
}
